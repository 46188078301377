import Vue from 'vue'
import App from './App.vue'
import {
  BootstrapVue,
  BootstrapVueIcons,
  IconsPlugin,
  AlertPlugin,
  NavbarPlugin
} from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './assets/css/custom.css'
import 'jquery'
import { popper } from '@popperjs/core'
import VueKeyCloak from '@dsb-norge/vue-keycloak-js'
import router from './router'

import { useAppInsights } from './services/AppInsights'

Vue.config.productionTip = false

// Install BootstrapVue
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(AlertPlugin)
Vue.use(NavbarPlugin)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
Vue.use(popper)

useAppInsights().trackEvent({ name: 'Aplicação carregada' })

Vue.use(VueKeyCloak, {
  logout: {
    redirectUri: window.origin
  },
  config: {
    url: process.env.VUE_APP_IDM_URL,
    realm: process.env.VUE_APP_IDM_REALM,
    clientId: process.env.VUE_APP_IDM_CLIENT_ID
  },
  init: {
    onLoad: 'check-sso',
    flow: 'hybrid',
  },
  // eslint-disable-next-line no-unused-vars
  onReady: (keycloak) => {
    new Vue({
      router,
      render: h => h(App)
    }).$mount('#app')
  }
})