import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/Index.vue'
import { ModalPlugin } from 'bootstrap-vue'

Vue.use(VueRouter, ModalPlugin)

const routes = [
  { 
    path: '/', 
    name: 'Index', 
    component: Index 
  },
  { 
    path: '/main', 
    name: 'MainUser', 
    component: () => import('../views/Main-User.vue'), 
    meta: { requiresAuth: true }
  },
  { 
    path: '*', 
    redirect: '/main' 
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (router.app.$keycloak.authenticated) {
      next()
    } else {
      router.app.$keycloak.login({redirectUri: window.location.origin + "/main"})
    }
  } else {
    next()
  }
})

export default router
